(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        // tabbed content
        // http://www.entheosweb.com/tutorials/css/tabs.as

        /**!
        Mobile toggle nav
        */
        $(".nav-button").click(function () {
          $(".mobile-primary-menu").slideToggle(500);
          $(".mobile-primary-menu").toggleClass("is-opened");
        });

        /**!
        Mobile submenu dropdown
        */
        $(".mobile-primary-menu .sub-menu-dropdown").click(function () {
          $(this).parent().toggleClass("is-opened");
          $(this).parent().children(".sub-menu").toggleClass("is-opened");
          $(this).parent().children(".sub-menu").slideToggle(400);
        });

        /**!
        Move weglot language switcher
        */
        function checkLanguageSwitcherPosition() {
          if (window.matchMedia("(max-width: 992px)").matches) {
            $("#language-switcher").appendTo("#language-switcher-mobile");
          } else {
            $("#language-switcher").appendTo("#language-switcher-desktop");
          }
        }

        checkLanguageSwitcherPosition();
        $(window).on("resize", checkLanguageSwitcherPosition);

        /**!
        Searchbox on mobile
        */
        //Mobile
        $("#mobile-searchbox-menu").click(function () {
          $("#mobile-searchbox").slideToggle(500);
        });

        $(window).resize(function () {
          if ($(this).width() > 992) {
            $("#mobile-searchbox").css("display", "none");
          }
        });

        /**!
        Sticky header
        */
        window.onscroll = function () {
          if (window.pageYOffset > 0 && window.innerWidth > 1050) {
            document.getElementById("header").classList.add("is-sticky");
          } else {
            document.getElementById("header").classList.remove("is-sticky");
          }
        };

        /**!
        Shop message
        */
        if ($("#msg-banner").length) {
          $("body").addClass("shop-message-visible");
        }

        this.initIsotopePalmares();
        this.initTabs();
        this.initAmbassadrice();
      },
      initIsotopePalmares: function () {
        var akysotope = $(".row-palmares").isotope({
          // options
          itemSelector: ".single-palmares",
          layoutMode: "fitRows",
          filter: ".2017",
        });
        $('.filter-palmares .cat-palmares[data-cat=".2017"]').addClass(
          "active"
        );

        $(".filter-palmares .cat-palmares").click(function () {
          var cat = $(this).attr("data-cat");
          $(".filter-palmares .cat-palmares").removeClass("active");
          $(this).addClass("active");
          akysotope.isotope({ filter: cat });
        });
      },
      initTabs: function () {
        $(".tab_content").hide();
        $(".section-extension .tab_content:first").show();
        $(".section-lashbotox .tab_content:first").show();
        $(".section-lashlift .tab_content:first").show();
        /* if in tab mode */
        $(".section-extension-li-tab li").click(function () {
          $(".section-extension .tab_content").hide();
          var activeTab = $(this).attr("rel");
          $("#" + activeTab).fadeIn();

          $(".section-extension-li-tab li").removeClass("active");
          $(this).addClass("active");

          $(".tab_drawer_heading").removeClass("d_active");
          $(".tab_drawer_heading[rel^='" + activeTab + "']").addClass(
            "d_active"
          );
        });

        $(".section-lashbotox-li-tab li").click(function () {
          $(".section-lashbotox .tab_content").hide();
          var activeTab = $(this).attr("rel");
          $("#" + activeTab).fadeIn();

          $(".section-lashbotox-li-tab li").removeClass("active");
          $(this).addClass("active");

          $(".tab_drawer_heading").removeClass("d_active");
          $(".tab_drawer_heading[rel^='" + activeTab + "']").addClass(
            "d_active"
          );
        });

        $(".section-lashlift-li-tab li").click(function () {
          $(".section-lashlift .tab_content").hide();
          var activeTab = $(this).attr("rel");
          $("#" + activeTab).fadeIn();

          $(".section-lashlift-li-tab li").removeClass("active");
          $(this).addClass("active");

          $(".tab_drawer_heading").removeClass("d_active");
          $(".tab_drawer_heading[rel^='" + activeTab + "']").addClass(
            "d_active"
          );
        });

        /* if in drawer mode */
        $(".tab_drawer_heading").click(function () {
          $(".tab_content").hide();
          var d_activeTab = $(this).attr("rel");
          $("#" + d_activeTab).fadeIn();

          $(".tab_drawer_heading").removeClass("d_active");
          $(this).addClass("d_active");

          $("ul.tabs li").removeClass("active");
          $("ul.tabs li[rel^='" + d_activeTab + "']").addClass("active");
        });
      },
      initAmbassadrice: function () {
        Array.from(document.querySelectorAll(".grid__item-img")).forEach(
          function (el) {
            const imgs = Array.from(el.querySelectorAll("img"));
            new HoverEffect({
              parent: el,
              intensity: el.dataset.intensity,
              speedIn: el.dataset.speedin,
              speedOut: el.dataset.speedout,
              easing: el.dataset.easing,
              hover: el.dataset.hover,
              image1: imgs[0].getAttribute("src"),
              image2: imgs[1].getAttribute("src"),
              displacementImage: el.dataset.displacement,
            });
          }
        );
      },
      finalize: function () {
        $(".timeline>.display-window>.years-wrapper li:first-child").addClass(
          "selected"
        );
        $(".events-content>ul li:first-child").addClass("selected");

        /* $('.events-content>ul').css('height', $('.events-content>ul li:first-child').height() + 'px');*/

        /*var lenght = $('.timeline>.display-window>.years-wrapper li').length();*/

        $(".timeline>.display-window>.years-wrapper li").click(function () {
          $(".timeline>.display-window>.years-wrapper li").each(function () {
            $(this).removeClass("selected");
          });
          $(this).addClass("selected");
          var datadate = $(this).data("date");
          var datanumberli = $(this).data("year");
          var numberlicount = 100 - datanumberli * 50;
          console.log(numberlicount);
          $(".timeline>.display-window>.years-wrapper").css(
            "transform",
            "translateY(" + numberlicount + "px)"
          );
          /* $('.years-wrapper').css({"transform":"translateY(" + 100 - numberlicount + "px)"});​*/
          $(".events-content>ul li").each(function () {
            $(this).removeClass("selected");
            if ($(this).data("date") === datadate) {
              $(this).addClass("selected");
              /*var heightcontent = $(this).height();
              console.log(heightcontent);
              $('.events-content>ul').css('height', heightcontent + 'px');*/
            }
          });
        });
      },
    },
    // Home page
    home: {
      init: function () {
        if ($(".home_slider .slide").length > 1) {
          new Splide(".home_slider .splide", {
            type: "loop",
            speed: 1000,
            interval: 3000,
            autoplay: true,
            heightRatio: 500 / 1110,
            breakpoints: {
              576: {
                arrows: false,
                heightRatio: 1,
              },
            },
          }).mount();
        } else {
          $(".home_slider .splide").removeClass("splide");
          $(".home_slider .splide__track").removeClass("splide__track");
          $(".home_slider .splide__list").removeClass("splide__list");
        }
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    // Formatrice template page
    page_template_template_formatrices: {
      init: function () {
        // JavaScript to be fired on the Formatrice template page
        $(document).on("mouseenter", ".city__marker", function (event) {
          $("[data-city]").removeClass("is-active");
          const city = $(event.currentTarget).data("city");
          $('*[data-city="' + city + '"]').addClass("is-active");
        });

        $(document).on("mouseleave", ".formatrices__map", function (event) {
          $("[data-city]").removeClass("is-active");
        });
      },
    },
    // Points de vente template page
    page_template_template_pdv: {
      init: function () {
        // JavaScript to be fired on the Points de vente template page
        $(document).on("mouseenter", ".city__marker", function (event) {
          $("[data-city]").removeClass("is-active");
          const city = $(event.currentTarget).data("city");
          $('*[data-city="' + city + '"]').addClass("is-active");
        });

        $(document).on("mouseleave", ".pdv__map", function (event) {
          $("[data-city]").removeClass("is-active");
        });
      },
    },
    // Instituts template page
    page_template_template_instituts: {
      init: function () {
        // JavaScript to be fired on the Instituts template page
        $(document).on("mouseenter", ".city__marker", function (event) {
          $("[data-city]").removeClass("is-active");
          const city = $(event.currentTarget).data("city");
          $('*[data-city="' + city + '"]').addClass("is-active");
        });

        $(document).on("mouseleave", ".instituts__map", function (event) {
          $("[data-city]").removeClass("is-active");
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
